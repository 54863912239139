@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "../css/variables.scss";

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #f6f6f6;
  padding-top: 5px;
}
.badge-static-class{
  height: 24px;
  margin-bottom: 4px;
  font-size: 12px;
}
.font-9 {
  font-size: 9px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-28 {
  font-size: 28px;
}
.heading-1 {
  font-size: 29px;
  font-weight: 500;
}
.font-bold {
  font-weight: bold !important;
}
.font-bold {
  font-weight: $font-bold;
}
.font-medium {
  font-weight: $font-medium;
}
.font-light {
  font-weight: $font-light;
}
.font-regular {
  font-weight: $font-regular;
}

a {
  text-decoration: none;
  transition: 0.4s;
  &:hover {
    color: $color-secondary !important;
  }
}
.boxshadow {
  background: #ffffff;
  border-radius: 5px;
  min-height: 75px;
}
.boxshadow1 {
  background: #ffffff;
  border-radius: 5px;
}
.weatherbg {
  background-image: url("../images/weatherbg.jpg");
  background-size: cover;
}
.text-brand {
  color: $text-primary;
}
.text-down {
  color: $text-down;
}
.btn-brand-1 {
  background-color: $color-primary;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-weight: 500;
  height: 42px;
  border: 0;
  padding: 7px 27px;
  line-height: 25px;
  &:hover {
    background-color: $color-secondary;
    color: #fff;
  }
}

// left menu css
.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  .header-nav {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.layout-veritcle-menu {
  display: flex;
  min-height: 100vh;
  .verticle-menu {
    background-color: #fff;
    width: 84px;
    height: calc(100vh - 70px);
    position: sticky;
    top: 71px;
    padding-top: 35px;
    z-index: 4;

    &::before {
      content: "";
      width: 50px;
      height: 50px;
      background-image: url("../../assets/images/page-corner.svg");
      background-size: contain;
      position: fixed;
      left: 77px;
      top: 79.5px;
      background-repeat: no-repeat;
    }
    .menu-list {
      .menu-item {
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 44px;
        border-radius: 22px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        &:hover,
        &:focus {
          opacity: 1;
          background-color: $color-primary-light;
        }
        .menu-icon {
          width: 22px;
        }
      }
      .dropdown-toggle {
        @extend .menu-item;
        border: 0;
        background-color: transparent;
        width: 100%;
        border-radius: 6px 0 0 6px;
        &::after {
          border: 0;
        }
        &.show {
          background-color: #1c1c1c;
          opacity: 1;
        }
      }
      .dropend {
        text-align: center;
      }
      .dropdown-menu {
        left: -3px !important;
        border-radius: 0 6px 6px 0;
        background-color: #1c1c1c;
        .dropdown-item {
          color: #fff;
          font-size: 14px;
          padding: 9px 13px;
          margin-bottom: 3px;
          &:hover {
            background-color: #ffffff2b;
            color: #ffffff !important;
          }
        }
      }
    }
    .tooltip-inner {
      background-color: #343434 !important;
    }
  }
  .app-body {
    width: calc(100% - 75px);
    padding-top: 100px;
    zoom: 0.9;
    -ms-zoom: 0.9;
}
}

// header

.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  .header-nav {
    .navbar {
      padding: 0;
    }
  }
  .header-nav {
    background-color: #fff;
    .v-devider {
      display: inline-block;
      width: 1px;
      background: #525252;
      height: 20px;
      margin: 0 15px;
    }
  }

  .profile-dd {
    .dropdown-menu {
      min-width: 200px;
      top: 68px;
      border: 0;
      box-shadow: 0px 3px 10px #0000002b;
      .dropdown-item:hover a,
      .dropdown-item:hover .icon {
        color: #000 !important;
      }
    }
    .btn {
      background-color: #f8f8f8;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0;
      &::after {
        border: 0;
      }
      border-radius: 30px;
      padding: 7px 10px;
    }
    .icon {
      width: 18px;
      height: 18px;
      margin-right: -4px;
      color: #4b4b4b;
    }
  }
  .help-btn {
    background-color: #f8f8ff;
    font-size: 14px;
    color: $color-primary;
    font-weight: $font-medium;
    height: 48px;
    padding: 8px 15px;
  }
}


.height100vh {
  height: 97vh;
}

@media (min-width: 1250px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px !important;
  }
}
.border-right {
  border-right: 1px solid #ccc;
}
.bordergreen-left {
  border-left: 10px solid #489a43;
}
.borderGood-left {
  border-left: 10px solid #489a43;
}
.borderSatisfactory-left {
  border-left: 10px solid #70a13f;
}
.borderModerate-left {
  border-left: 10px solid #c6c608;
}
.borderPoor-left {
  border-left: 10px solid #c59506;
}
.borderVeryPoor-left {
  border-left: 10px solid #c60804;
}
.borderSever-left {
  border-left: 10px solid #960504;
}
.profilestyle {
  h1 {
    font-size: 35px;
    color: #489a43;
    font-weight: bold;
    margin-bottom: 0px;
    line-height: 37px;
  }
  p {
    font-size: 12px;
    color: #211e1e;
    margin-bottom: 0px;
  }
  .update {
    color: #414141;
    font-size: 10px;
  }
  .weather {
    background-color: #eeffe3 !important;
    color: #489a43;
    border: 1px solid #489a43;
    height: 24px;
    margin-bottom: 4px;
    font-size: 12px;
  }
  .moderate {
    background-color: #f4f7df!important;
    color: #aaaa0b;
    border: 1px solid #C6C608;
    height: 24px;
    margin-bottom: 4px;
    font-size: 12px;
  }
  .satisfactory {
    background-color: #e1f1d0!important;
    color: #70A13F;
    border: 1px solid #70A13F;
    height: 24px;
    margin-bottom: 4px;
    font-size: 12px;
  }
  .poor {
    background-color: #f3ecd7!important;
    color: #C59506;
    border: 1px solid #C59506;
    height: 24px;
    margin-bottom: 4px;
    font-size: 12px;
  }
  .verypoor {
    background-color: #f5d1d1!important;
    color: #C60804;
    border: 1px solid #C60804;
    height: 24px;
    margin-bottom: 4px;
    font-size: 12px;
  }
  .severe {
    background-color: #f7dddd!important;
    color: #960504;
    border: 1px solid #960504;
    height: 24px;
    margin-bottom: 4px;
    font-size: 12px;
  }
}
// responsive css
@media (max-width: 767px) {
}
.qrcode h5 {
  font-size: 12px;
  color: #414141;
  font-weight: 400;
}
.live {
  h1 {
    color: #385723;
    font-size: 16px;
  }
  .live20 {
    font-weight: bold;
  }
  h4 {
    color: #278c5d;
  }
  h6 {
    color: #278c5d;
  }
}
.weatherbg {
  h5 {
    font-size: 20px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
  }
}
.mainheading {
  font-size: 12px;
  color: #211e1e;
  font-weight: bold;
}
.border-brand {
  border: 1px solid #278c5d;
}

.indoor .boxes {
  background-color: #e9ffe8;
  border-radius: 5px;
}

.ambient {
  .boxes {
    border: 1px solid #eeeeee;
    border-radius: 5px;
  }
}
.verygood {
  background: #cffde8 !important;
  border-radius: 10px;
  margin-top: -12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.average {
  background: #d9ec85;
  border-radius: 10px;
  margin-top: -12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.bad {
  background: #c4f379 !important;
  border-radius: 10px;
  margin-top: -12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.verybad {
  background: #fdcfcf !important;
  border-radius: 10px;
  margin-top: -12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.indoorair {
  background: #fffdf5;
}

.margin-bottom21 {
  margin-bottom: 21px !important;
}

.mainheading .font-100 {
  font-weight: 400 !important;
}
.indoordata {
  .boxes {
    background-color: #fefae9;
    border-radius: 5px;
    padding:5px 15px !important;
  }
}
.aircircle {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-bottom: 7px;
  img {
    width: 18px;
    height: 18px;
}
}

.weather {
  .text {
    font-size: 30px;
    color: #206961;
    font-weight: 500;
  }
  font-size: 30px;
  color: #fbffe3;
  font-weight: 500;
}

.satisfy {
  .text {
    font-size: 30px;
    color: #206961;
    font-weight: 500;
  }
  font-size: 30px;
  color: #fffae3;
  font-weight: 500;
  
}
.weather_moderate {
  .text {
    font-size: 30px;
    color: #206961;
    font-weight: 500;
  }
  font-size: 30px;
  color: #fbffe3;
  font-weight: 500;
}
// }

.CircularProgressbar .CircularProgressbar-path {
  // stroke: #44c056 !important;
}
.progressbar {
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -19px;
    margin-top: -19px;
    background-color: #e2f8e0;
    border-radius: 50px;
    padding: 7px;
    height: 40px;
    width: 40px;
  }
}

.progressbar1 {
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #e2f8e0;
    border-radius: 50px;
    padding: 7px;
    height: 40px;
    width: 40px;
  }
}

.graphbg {
  background-color: #e2f8e0;
  border-radius: 50px;
  padding: 7px;
  height: 50px;
  width: 50px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.height{
  height: 100px;
}
.slowveryfase {
  width: 78px;
  margin-right: 0px !important;
  margin-top: 0px !important;
}
.speed-meter .slowveryfase{
  width: auto;
} 
.heightair{
  height: 75px;
  padding-left: 14px !important;
}

@media (max-width: 1280px) {
  .layout-veritcle-menu {
      .app-body {
          zoom: 0.8;
          -ms-zoom: 0.8;
          padding-top: 110px;
      }

      .menu-list .menu-item,
      .sub-title {
          font-size: 12px !important;
      }

      .commandCentre-page {
          .margin-mins {
              float: left;
              margin-top: 6px;
              margin-right: 6px;
          }

          .white-card-box {
              padding: 10px;
          }

          img.margin-mins {
              width: 30px;
              height: 30px;
              object-fit: contain;
          }
      }
  }
}

@media (max-width: 1024px) {
  .layout-veritcle-menu {
      .app-body {
          zoom: 0.7;
          -ms-zoom: 0.7;
          padding-top: 110px;
      }
  }
  }


.strip{
  background: #385723;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  color: #fff;
  font-weight: normal;
}


@media(max-width:1920px){
  #gauge-chart3 {
    height:160px !important;
  }

#gauge-chart3 {
    height:195px;

    clear: both;
    overflow: hidden;
  }
  .speed-meter #gauge-chart3 {
    height: 115px !important;
  }
  .react-charts-height{
    height: 145px !important;
    width: 100%;
  }
}

@media(max-width:1440px){
  #gauge-chart3 {
    height:140px !important;
    clear: both;
    overflow: hidden;
  }
  .speed-meter #gauge-chart3 {
    height: 115px !important;
  }
  .react-charts-height{
    height: 125px !important;
    width: 100%;
  }
}

@media(max-width:1366px){
  #gauge-chart3 {
    height:85px !important;
    width: 60%;
    clear: both;
    overflow: hidden;
  }
  .react-charts-height{
    height: 125px !important;
    width: 100%;
  }
}

@media(max-width:1280px){
  #gauge-chart3 {
    height: 110px !important;
  }
  .speed-meter #gauge-chart3 {
    height: 115px !important;
  }
  .react-charts-height{
    height: 90px !important;
    width: 100%;
  }
}

img.logo-width-just {
  width: 180px;
  height: 25px;
  object-fit: contain;
}

img.logo-width-ds {
  width: 90px;
  height: 55px;
  object-fit: contain;
}

.ptb{
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important;
}

.padding-meter-label{
  padding-right: 1.25rem !important;
  padding-left: 0.7rem !important;
}